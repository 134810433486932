import type { NavLink } from "./Header.types";

export const NAV_LINKS: NavLink[] = [
  { link: "/work", label: "Work" },
  { link: "/about", label: "About" },
  { link: "/contact", label: "Contact" },
  { link: "http://blog.richardekwonye.com/", label: "Blog", target: "_blank" },
];

export const MOBILE_MENU_EXIT_DURATION = 700;
